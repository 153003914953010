<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
		<!-- <div class="nav-logo">LOGO</div> -->
		<div class="navbar-brand">
			<router-link class="navbar-item" to="/"><icon-base icon-name="logo" view-box="0 0 97 97" width="40" height="45" icon-color="none"><icon-logo /></icon-base></router-link>

			<a role="button" class="navbar-burger" @click="burgerClick" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>
		
		<div :class="'navbar-menu ' + burgerMenu">
			<div class="navbar-start">
				<router-link class="navbar-item" to="/">Úvod</router-link> 
				<router-link class="navbar-item" to="/novinky">Novinky</router-link> 
				<router-link class="navbar-item" to="/cenik">Ceník</router-link> 
				<!-- <router-link class="navbar-item" to="/admin/zakazka">Spočítat zakázku</router-link> -->
				<!-- <router-link class="navbar-item" to="/youtube.videa">Videa na youtube</router-link> -->
				<router-link class="navbar-item" to="/kontakt">Kontakt</router-link>
			</div>
		</div>
	</nav>
</template>

<script>
import IconLogo from '@/components/icons/IconLogo.vue'
import IconBase from '@/components/icons/IconBase.vue'
import ClickOutside from 'vue-click-outside'

export default {
	name: 'Toolbar',
  		components: {
    	IconLogo,
    	IconBase
  	},
	directives: {
    	ClickOutside
  	},
  	props: {
    	drawerToggleClicked: Boolean
  	},
  	data(){
	  	return {
			burgerMenu: '',
			// isDialbogueOpen: false
		}
  	},
  	methods: {
		burgerClick() {
			// this.isDialbogueOpen ? this.isDialbogueOpen = false : this.isDialbogueOpen = true;
			this.burgerMenu ? this.burgerMenu = '' : this.burgerMenu = 'is-active';
			// console.log(this.burgerMenu);
		},

  	}
}
</script>