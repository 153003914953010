<template>
    <footer class="footer">
        <div class="content has-text-centered">
            © {{(new Date()).getFullYear()}} <a href="https://smashedgarage.com/">smashedgarage.com</a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterBar',
}
</script>