<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
    :fill="iconColor"
  >
    <title
      :id="iconName"
      lang="en"
    >{{ iconName }} icon</title>
    <g >
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
    name: 'IconBase',
    props: {
        iconName: {
            type: String,
            default: 'box'
        },
        width: {
            type: [Number, String],
            default: "2rem"
        },
        height: {
            type: [Number, String],
            default: "2rem"
        },
        iconColor: {
            type: String,
            default: 'black'
        },
        viewBox: {
            type: [String],
            default: "0 0 512 512"
        }
    }
}
</script>

<style scoped>
    svg {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: -2px; 
    }
</style>