<template>
	<div class="card">
		<div class="card-content">
			<div class="social-hub">
					<a href="https://www.youtube.com/c/smashedgarage" target="_blank" rel="noopener noreferrer">
						<icon-base icon-name="youtube" width="2rem" height="2rem" icon-color="black"><icon-youtube /></icon-base>
					</a>
					<a href="https://www.facebook.com/smashedgarage" target="_blank" rel="noopener noreferrer">
						<icon-base icon-name="facebook" width="2rem" height="2rem" icon-color="black"><icon-facebook /></icon-base>
					</a>
					<a href="https://www.instagram.com/smashedgarage/" target="_blank" rel="noopener noreferrer">
						<icon-base icon-name="instagram" width="2rem" height="2rem" icon-color="black"><icon-instagram /></icon-base>
					</a>
					<a href="https://www.linkedin.com/company/smashedgarage" target="_blank" rel="noopener noreferrer">
						<icon-base icon-name="linkedin" width="2rem" height="2rem" icon-color="black"><icon-linked-in /></icon-base>
					</a>
					<a href="https://twitter.com/smashedgarage" target="_blank" rel="noopener noreferrer">
						<icon-base icon-name="twitter" width="2rem" height="2rem" icon-color="black"><icon-twitter /></icon-base>
					</a>
			</div>
		</div>
	</div>
</template>

<script>
import IconBase from '@/components/icons/IconBase.vue'
import IconInstagram from '@/components/icons/IconInstagram.vue'
import IconTwitter from '@/components/icons/IconTwitter.vue'
import IconYoutube from '@/components/icons/IconYoutube.vue'
import IconLinkedIn from '../../icons/IconLinkedIn.vue'
import IconFacebook from '../../icons/IconFacebook.vue'

export default {
	name: 'SocialHub',
	components: {
		IconBase,
		IconInstagram,
		IconTwitter,
		IconYoutube,
		IconLinkedIn,
		IconFacebook
	},
}
</script>


IconFacebook<style>
	.social-hub {
			width: 50%;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding-bottom: 2rem;
	}

	.social-hub svg:hover {
		fill: #960000;
	}

	.card-content {
		width: 100%;
	}
</style>