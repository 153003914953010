<template>
  <div id="app">
	<toolbar/>
	<div class="container">
		<router-view/>
	</div>
	<footer-bar/>
  </div>
</template>

<script>
import Toolbar from '@/components/navigation/Toolbar.vue'
import FooterBar from '@/components/navigation/FooterBar.vue'

export default {
	components: {
		Toolbar,
		FooterBar
	}
}
</script>

<style>
	html {
		height: 100%;
	}

	body {
		margin: auto;
		height: 100%;
		display: flex;
        min-height: 100vh;
        flex-direction: column;
	}

	body > #id {
		height: 100%;
	}
</style>
