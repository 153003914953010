<template>
	<div class="content">
		<div class="logo">
			<icon-base icon-name="logo" view-box="0 0 91 96" width="160" height="160" icon-color="none"><router-link to="/"><icon-logo /></router-link></icon-base>
		</div>

		<div class="card">
			<div class="card-content">
				<div class="content has-text-centered">
					<h1><b>smashedgarage</b> je otevřená!</h1>
					<p>Je potřeba se vždy objednat po telefonu, messengeru, whatsapu..</p>
					<table>
						<tr>
							<td>Pondělí</td>
							<td>18:00 - 21:00</td>
						</tr>
						<tr>
							<td>Úterý</td>
							<td>18:00 - 21:00</td>
						</tr>
						<tr>
							<td>Středa</td>
							<td>18:00 - 21:00</td>
						</tr>
						<tr>
							<td>Čtvrtek</td>
							<td>18:00 - 21:00</td>
						</tr>
						<tr>
							<td>Pátek</td>
							<td>18:00 - 21:00</td>
						</tr>
					</table>
					<p>Sobota a neděle nejsou problém po předchozí domluvě.</p>
					<p>Objednávej na tel: +420 737 478 542</p>
				</div>
			</div>
		</div>
		<social-hub/>
	</div>
</template>

<script>
import SocialHub from '@/components/ui/socialhub/SocialHub.vue'
import IconBase from '@/components/icons/IconBase.vue'
import IconLogo from '@/components/icons/IconLogo.vue'

export default {
	name: 'Home',
	components: {
		SocialHub,
		IconLogo,
		IconBase,
	}
}
</script>


<style>
	.logo {
		width: 100%;
		height: 100%;
	}

	.logo svg {
		margin: auto;
		display: block;
	}

	a {
		text-decoration: none;
	}

	a:hover {
		color: 	#960000;
	}
</style>