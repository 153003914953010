import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueFbCustomerChat from 'vue-fb-customer-chat'

require('@/assets/main.scss');

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(VueYouTubeEmbed)
Vue.use(VueFbCustomerChat, {
	page_id: 109750391305629,
	theme_color: '#333333',
	locale: 'cs_CZ'
})


Vue.$cookies.set('allowCookies', false, null, null, null, null, "Lax");

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
