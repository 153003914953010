import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

	const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	// {
	// 	path: '/o-mne',
	// 	name: 'AboutMe',
	// 	component: () => import('../views/AboutMe.vue')
	// },
	// {
	// 	path: '/o.nas',
	// 	name: 'AboutUs',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
	// },
	{
		path: '/novinky',
		name: 'Updates',
		component: () => import('../views/Updates.vue')
	},
	{
		path: '/cenik',
		name: 'PriceList',
		component: () => import('../views/PriceList.vue')
	},
	{
		path: '/youtube.videa',
		name: 'YTVideos',
		component: () => import('../views/YTVideos.vue')
	},
	{
		path: '/kontakt',
		name: 'Contact',
		component: () => import('../views/Contact.vue')
	},
	{
		path: '/admin/zakazka',
		name: 'PriceCalculator',
		component: () => import('../views/PriceCalculator.vue')
	},
	{
		path: '/rezervace',
		name: 'Reservation',
		component: () => import('../views/Reservation.vue')
	},
	{
		path: '*',
		name: '404 not found',
		component: () => import('../views/404.vue')
	},
]

const router = new VueRouter({
	routes,
	mode: 'history'
})

export default router
